import React, { useState, useCallback, useEffect } from "react";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  updatePublisherApi,
  uploadCompanyDocsApi,
} from "../../../../api/company";
import Loader from "react-loader-spinner";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import UpdateCompanyCover from './UpdateCompanyCover';
import api_config from "../../../../api/api_config"


const schema = yup
  .object({
    companyName: yup
      .string()
      .strict(false)
      .trim()
      .required()
      .max(255)
      .label("Company Name"),
    commission: yup.number().required().min(0).max(100),
    bankName: yup
      .string()
      .strict(false)
      .trim()
      .required()
      .max(255)
      .label("Bank Name"),
    bankBranch: yup
      .string()
      .strict(false)
      .trim()
      .required()
      .max(255)
      .label("Branch Name"),
    accountName: yup
      .string()
      .strict(false)
      .trim()
      .required()
      .label("Account Name"),
    accountNumber: yup
      .string()
      .strict(false)
      .trim()
      .required()
      .label("Account Number"),
    swiftCode: yup.string().strict(false).trim().required().label("Swift Code"),
    tin: yup
      .string()
      .strict(false)
      .trim()
      .required()
      .max(60)
      .label("Tin Number"),
  })
  .required();

function EditCompany(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const location = useLocation();
  const values = location.state.data;
  const [thedata, setData] = useState(values);
  const [changeVisibility, setChangeVisibility] = useState(false)

  const [loading, setLoading] = useState(false);
  const [tinCertificate, setTinCertificate] = useState();
  const [businessLicense, setBusinessLicense] = useState();
  const [publishingCertificate, setPublishingCertificate] = useState();
  const [certificateOfIncorporation, setCertificateOfIncorporation] =
    useState();
  const [nationalIdLetter, setNationalIdLetter] = useState();
  const [coverFile, setCoverFile] = useState();
  const [countries, setCountries] = useState([]);
  const [publisherId, setPublisherId] = useState(null);
  const [country, setCountry] = useState({
    name: "Tanzania",
    currency: "TZS",
    countryCode: "TZ",
  });

  const onDropBusinessLicense = useCallback((uploadedFiles) => {
    readFiles(uploadedFiles, 1);
  }, []);

  const onDropPublishingCertificate = useCallback((uploadedFiles) => {
    readFiles(uploadedFiles, 2);
  }, []);

  const onDropCertificateOfIncorporation = useCallback((uploadedFiles) => {
    readFiles(uploadedFiles, 3);
  }, []);

  const onDropNationalIdLetter = useCallback((uploadedFiles) => {
    readFiles(uploadedFiles, 4);
  }, []);

  const onDropCover = useCallback((uploadedFiles) => {
    readFiles(uploadedFiles, 5);
  }, []);

  const onDropTin = useCallback((uploadedFiles) => {
    readFiles(uploadedFiles, 6);
  }, []);

  function readFiles(file, type) {
    console.log(file);
    if (file[0]) {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reader aborted");

      reader.onerror = () => console.log("file reader failed");

      reader.onload = () => {
        const dataUrl = reader.result;
        console.log(dataUrl);

        switch (type) {
          case 1:
            setBusinessLicense({
              url: dataUrl,
            });
            break;

          case 2:
            setPublishingCertificate({
              url: dataUrl,
            });
            break;

          case 3:
            setCertificateOfIncorporation({
              url: dataUrl,
            });
            break;

          case 4:
            setNationalIdLetter({
              url: dataUrl,
            });
            break;

          case 5:
            setCoverFile({
              url: dataUrl,
            });
            break;
          case 6:
            setTinCertificate({ url: dataUrl });
            break;
          default:
            break;
        }
      };

      // Read the file as a data url
      reader.readAsDataURL(file[0]);
    }
  }

  // handle su
  const onSubmit = (data) => {
    setLoading(true);

    if (tinCertificate) data.tinCertificate = tinCertificate;
    if (businessLicense) data.businessLicense = businessLicense;
    if (publishingCertificate)
      data.publishingCertificate = publishingCertificate;
    if (nationalIdLetter) data.nationalIdLetter = nationalIdLetter;
    if (certificateOfIncorporation)
      data.certificateOfIncorporation = certificateOfIncorporation;
    if (coverFile) data.coverImage = coverFile;
    data.publisherId = publisherId;
    if (
      data.companyName &&
      data.companySize &&
      data.commission &&
      data.country &&
      data.address &&
      data.city
    ) {
      updatePublisherApi(thedata.id,data)
        .then((result) => {
          console.log("result: ", result.data);
          if (result.data.status == 200) {
            setPublisherId(result.data.data.id);
            toast.success(result.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            navigate("/publishers/company");
          } else {
            toast.error(result.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((e) => {
          console.log("sss: ", e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error("Fill in required information", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const navigate = useNavigate();

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }
  const handleSave = (e) => {
    e.preventDefault();
    setLoading(true);
    let companyName = document.getElementById("companyName").value.trim();
    let companySize = document.getElementById("companySize").value.trim();
    let commission = document.getElementById("commission").value.trim();
    let address = document.getElementById("address").value.trim();
    let country = document.getElementById("country").value.trim();
    let city = document.getElementById("city").value.trim();

    let data = {
      companyName: companyName,
      companySize: companySize,
      commission: commission,
      address: address,
      country: country,
      city: city,
    };
    console.log("dat :", data);
    if (tinCertificate) data.tinCertificate = tinCertificate;
    if (businessLicense) data.businessLicense = businessLicense;
    if (publishingCertificate)
      data.publishingCertificate = publishingCertificate;
    if (nationalIdLetter) data.nationalIdLetter = nationalIdLetter;
    if (certificateOfIncorporation)
      data.certificateOfIncorporation = certificateOfIncorporation;
    if (coverFile) data.coverImage = coverFile;

    if (
      data.companyName &&
      data.companySize &&
      data.commission &&
      data.country &&
      data.address &&
      data.city
    ) {
      updatePublisherApi(thedata.id,data)
        .then((result) => {
          console.log("result: ", result.data);
          if (result.data.status == 200) {
            setPublisherId(result.data.data.id);
            toast.success(result.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(result.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((e) => {
          console.log("sss: ", e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error("Fill in required information", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const handleSaveDocs = (e) => {
    e.preventDefault();
    setLoading(true);
    if (publisherId) {
      let data = {
        companyId: publisherId,
      };
      console.log("datee :", data);
      if (tinCertificate) data.tinCertificate = tinCertificate;
      if (businessLicense) data.businessLicense = businessLicense;
      if (publishingCertificate)
        data.publishingCertificate = publishingCertificate;
      if (nationalIdLetter) data.nationalIdLetter = nationalIdLetter;
      if (certificateOfIncorporation)
        data.certificateOfIncorporation = certificateOfIncorporation;

      if (
        data.tinCertificate ||
        data.businessLicense ||
        data.publishingCertificate ||
        data.nationalIdLetter ||
        data.certificateOfIncorporation
      ) {
        updatePublisherApi(thedata.id,data)
          .then((result) => {
            console.log("result: ", result.data);
            if (result.data.status == 200) {
              toast.success(result.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              toast.error(result.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
          .catch((e) => {
            console.log(": ", e);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        toast.error("Fill in required information", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      setLoading(false);
      toast.error("Fill in required information", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  useEffect(() => {
    let countries = localStorage.getItem("RIFALYCOUNTRIES")
      ? JSON.parse(localStorage.getItem("RIFALYCOUNTRIES"))
      : [];
    setCountries(countries);
  }, []);

  return (
    <DashboardWrapper>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-10 col-xl-12'>
            <div className='header mt-md-3'>
              <nav aria-label='breadcrumb mb-2'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <a
                      href='/dashboard'
                      onClick={toPage("/dashboard")}>
                      Dashboard
                    </a>
                  </li>
                  <li className='breadcrumb-item'>
                    <a
                      href='/dashboard'
                      onClick={toPage("/publishers")}>
                      Publishers
                    </a>
                  </li>
                  <li className='breadcrumb-item'>
                    <a
                      href='/dashboard'
                      onClick={toPage("/publishers/company")}>
                      Companies
                    </a>
                  </li>
                  <li
                    className='breadcrumb-item active'
                    aria-current='page'>
                    Edit Company
                  </li>
                </ol>
              </nav>
            </div>

            <div className='content'>
              <div className='row mt-3'>
                <div className='col-md-12'>
                  <div className='card pt-3'>
                    <div className='px-4 pt-4'>
                      <div className=''>
                        <h6 className='header-pretitle'>Overview</h6>

                        <h1 className='header-title'>Edit Company</h1>
                      </div>

                      <hr />

                      <div className='row'>
                        <div className='col-md-12'>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='row mb-3'>
                              <div className='col-md-12'>
                                <div className='form-head'>
                                  <p className='form-title'>
                                    Basic Information
                                  </p>
                                </div>
                              </div>
                              <div className='col-md-6'>
                                      <div className='image-holder'>

                                        <div className='img-place'>
                                          <div className="img-cover" 
                                                onMouseEnter={e => {
                                                  setChangeVisibility(true);
                                                }}
                                                onMouseLeave={e => {
                                                  setChangeVisibility(false)
                                                }}
                                                >
                                            <img src={(thedata.cover && thedata.cover.includes('https')) ? `${thedata.cover}` : `${api_config.STORAGE_URL}${thedata.cover}`} className="img-fluid" alt={thedata.companyName} />
                                            {/* <img src={api_config.STORAGE_URL+thedata.cover} className="img-fluid" alt={thedata.companyName} /> */}
                                          </div>

                                          

                                          <div className={ changeVisibility ? 'img-change' : 'img-change hidden'}
                                              onMouseEnter={e => {
                                                setChangeVisibility(true);
                                              }}
                                              onMouseLeave={e => {
                                                setChangeVisibility(false)
                                              }}>
                                                <UpdateCompanyCover name={thedata}></UpdateCompanyCover>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>

                            <div className='row'>
                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='company'
                                    className='form-label'>
                                    Company Name
                                  </label>
                                  <input
                                    type='text'
                                    defaultValue={thedata.companyName}
                                    className={
                                      errors.companyName
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id='companyName'
                                    placeholder='Enter Company name'
                                    autoFocus
                                    required
                                    {...register("companyName")}
                                  />

                                  <p className='errors'>
                                    {errors.companyName?.message}
                                  </p>
                                </div>
                              </div>

                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='companySize'
                                    className='form-label'>
                                    Company Size (Employees)
                                  </label>
                                  <select
                                    className='form-select form-control'
                                    defaultValue={thedata.companySize}
                                    id='companySize'
                                    {...register("companySize")}
                                    required>
                                    <option>Select Company Size</option>
                                    <option value='1'>1 - 10 </option>
                                    <option value='2'>10 - 20 </option>
                                    <option value='3'>20 - 30 </option>
                                    <option value='4'>50 and above</option>
                                  </select>
                                </div>
                              </div>
                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='commision'
                                    className='form-label'>
                                    Commission (%)
                                  </label>
                                  <input
                                    type='number'
                                    defaultValue={thedata.commission}
                                    className={
                                      errors.commission
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id='commission'
                                    {...register("commission")}
                                    placeholder='Enter Commision Percentage'
                                    required
                                  />

                                  <p className='errors'>
                                    {errors.commission?.message}
                                  </p>
                                </div>
                              </div>
                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='country'
                                    className='form-label'>
                                    Country
                                  </label>

                                  <select
                                    className='form-select'
                                    {...register("country")}
                                    id='country'
                                    defaultValue={thedata.countryCode}
                                    onChange={(e) => {
                                      setCountry(
                                        countries.find(
                                          (c) =>
                                            c.countryCode == e.target.value,
                                        ),
                                      );
                                    }}>
                                    {countries.map((country) => (
                                      <option
                                        value={country.countryCode}
                                        key={country.countryCode}>
                                        {country.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='city'
                                    className='form-label'>
                                    City
                                  </label>
                                  <input
                                    type='text'
                                    defaultValue={thedata.city}
                                    className='form-control'
                                    id='city'
                                    {...register("city")}
                                    required
                                    placeholder='Enter City'
                                  />
                                </div>
                              </div>
                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='address'
                                    className='form-label'>
                                    Company Address
                                  </label>
                                  <input
                                    type='text'
                                    defaultValue={thedata.address}
                                    className='form-control'
                                    id='address'
                                    {...register("address")}
                                    required
                                    placeholder='Enter Company Address'
                                  />
                                </div>
                              </div>
                              <div className='col-md-4 row'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='tin'
                                    className='form-label'>
                                    Company TIN Number *
                                  </label>
                                  <input
                                    type='text'
                                    defaultValue={thedata.tin}
                                    className={"form-control"}
                                    id='tin'
                                    {...register("tin")}
                                    required
                                    placeholder='Enter your company’s TIN Number'
                                  />
                                  <p className='errors'>
                                    {errors.tin?.message}
                                  </p>
                                </div>
                              </div>

                              <div className='row my-4'>
                                <div className='col'></div>
                                <div className='col-auto'>
                                  {loading ? (
                                    <button className='btn btn-primary'>
                                      <Loader
                                        type='TailSpin'
                                        color='#ffffff'
                                        height={20}
                                        width={20}
                                      />
                                    </button>
                                  ) : (
                                    <button
                                      className='btn btn-primary'
                                      onClick={handleSave}>
                                      Save
                                    </button>
                                  )}
                                  {/* <button className="btn btn-primary" type="submit">Submit</button> */}
                                </div>
                              </div>
                              <div className='col-md-12'>
                                <div className='form-head'>
                                  <p className='form-title'>
                                    Company Documents
                                  </p>
                                </div>
                              </div>

                              <div className='col-md-6'>
                                <Dropzone
                                  onDrop={onDropTin}
                                  multiple={false}
                                  accept='.pdf'>
                                  {({ getRootProps, getInputProps }) => (
                                    <section>
                                      <div className='mb-1'>
                                        <label className='form-label'>
                                          Upload a copy of your TIN Certificate
                                        </label>
                                      </div>
                                      <div
                                        {...getRootProps({
                                          className:
                                            "dropzone dropzone-single mb-3",
                                        })}>
                                        <input {...getInputProps()} />
                                        <div className='upload-content p-4'>
                                          <i className='fe fe-upload-cloud'></i>
                                          <p>
                                            Drag and drop or click to upload
                                          </p>
                                          <p className='btn btn-sm btn-outline-primary'>
                                            Browse from your files
                                          </p>
                                        </div>
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                              </div>

                              <div className='col-md-6'>
                                <Dropzone
                                  onDrop={onDropBusinessLicense}
                                  multiple={false}
                                  accept='.pdf'>
                                  {({ getRootProps, getInputProps }) => (
                                    <section>
                                      <div className='mb-1'>
                                        <label className='form-label'>
                                          Upload a copy of your Business License
                                        </label>
                                      </div>
                                      <div
                                        {...getRootProps({
                                          className:
                                            "dropzone dropzone-single mb-3",
                                        })}>
                                        <input {...getInputProps()} />
                                        <div className='upload-content p-4'>
                                          <i className='fe fe-upload-cloud'></i>
                                          <p>
                                            Drag and drop or click to upload
                                          </p>
                                          <p className='btn btn-sm btn-outline-primary'>
                                            Browse from your files
                                          </p>
                                        </div>
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                              </div>

                              <div className='col-md-6'>
                                <Dropzone
                                  onDrop={onDropPublishingCertificate}
                                  multiple={false}
                                  accept='.pdf'>
                                  {({ getRootProps, getInputProps }) => (
                                    <section>
                                      <div className='mb-1'>
                                        <label className='form-label'>
                                          Upload a copy of your Publishing
                                          Certificate Licence
                                        </label>
                                      </div>
                                      <div
                                        {...getRootProps({
                                          className:
                                            "dropzone dropzone-single mb-3",
                                        })}>
                                        <input {...getInputProps()} />
                                        <div className='upload-content p-4'>
                                          <i className='fe fe-upload-cloud'></i>
                                          <p>
                                            Drag and drop or click to upload
                                          </p>
                                          <p className='btn btn-sm btn-outline-primary'>
                                            Browse from your files
                                          </p>
                                        </div>
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                              </div>

                              <div className='col-md-6'>
                                <Dropzone
                                  onDrop={onDropCertificateOfIncorporation}
                                  multiple={false}
                                  accept='.pdf'>
                                  {({ getRootProps, getInputProps }) => (
                                    <section>
                                      <div className='mb-1'>
                                        <label className='form-label'>
                                          Upload a copy of your Certificate of
                                          Incorporation
                                        </label>
                                      </div>
                                      <div
                                        {...getRootProps({
                                          className:
                                            "dropzone dropzone-single mb-3",
                                        })}>
                                        <input {...getInputProps()} />
                                        <div className='upload-content p-4'>
                                          <i className='fe fe-upload-cloud'></i>
                                          <p>
                                            Drag and drop or click to upload
                                          </p>
                                          <p className='btn btn-sm btn-outline-primary'>
                                            Browse from your files
                                          </p>
                                        </div>
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                              </div>

                              <div className='col-md-6'>
                                <Dropzone
                                  onDrop={onDropNationalIdLetter}
                                  multiple={false}
                                  accept='.pdf'>
                                  {({ getRootProps, getInputProps }) => (
                                    <section>
                                      <div className='mb-1'>
                                        <label className='form-label'>
                                          Upload a copy of your National
                                          Identification Letter
                                        </label>
                                      </div>
                                      <div
                                        {...getRootProps({
                                          className:
                                            "dropzone dropzone-single mb-3",
                                        })}>
                                        <input {...getInputProps()} />
                                        <div className='upload-content p-4'>
                                          <i className='fe fe-upload-cloud'></i>
                                          <p>
                                            Drag and drop or click to upload
                                          </p>
                                          <p className='btn btn-sm btn-outline-primary'>
                                            Browse from your files
                                          </p>
                                        </div>
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                              </div>

                              <div className='row my-4'>
                                <div className='col'></div>
                                <div className='col-auto'>
                                  {loading ? (
                                    <button className='btn btn-primary'>
                                      <Loader
                                        type='TailSpin'
                                        color='#ffffff'
                                        height={20}
                                        width={20}
                                      />
                                    </button>
                                  ) : (
                                    <button
                                      className='btn btn-primary'
                                      onClick={handleSaveDocs}>
                                      Save
                                    </button>
                                  )}
                                  {/* <button className="btn btn-primary" type="submit">Submit</button> */}
                                </div>
                              </div>

                              <div className='col-md-12'>
                                <div className='form-head'>
                                  <p className='form-title'>
                                    Billing Information
                                  </p>
                                </div>
                              </div>

                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='bankName'
                                    className='form-label'>
                                    Bank Name*
                                  </label>
                                  <input
                                    type='text'
                                    defaultValue={thedata.bankName}
                                    className={
                                      errors.bankName
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id='bankName'
                                    {...register("bankName")}
                                    required
                                    placeholder='Enter Bank Name'
                                  />

                                  <p className='errors'>
                                    {errors.bankName?.message}
                                  </p>
                                </div>
                              </div>
                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='bankBranch'
                                    className='form-label'>
                                    Bank Branch*
                                  </label>
                                  <input
                                    type='text'
                                    defaultValue={thedata.bankBranch}
                                    className={
                                      errors.bankBranch
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id='bankBranch'
                                    {...register("bankBranch")}
                                    required
                                    placeholder='Enter Bank Branch'
                                  />

                                  <p className='errors'>
                                    {errors.bankBranch?.message}
                                  </p>
                                </div>
                              </div>
                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='accountName'
                                    className='form-label'>
                                    Account Name*
                                  </label>
                                  <input
                                    type='text'
                                    defaultValue={thedata.accountName}
                                    className={
                                      errors.accountName
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id='accountName'
                                    {...register("accountName")}
                                    required
                                    placeholder='Enter Account Name'
                                  />
                                  <p className='errors'>
                                    {errors.accountName?.message}
                                  </p>
                                </div>
                              </div>
                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='accountNumber'
                                    className='form-label'>
                                    Account Number*
                                  </label>
                                  <input
                                    type='text'
                                    defaultValue={thedata.accountNumber}
                                    className={
                                      errors.accountNumber
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id='accountNumber'
                                    {...register("accountNumber")}
                                    required
                                    placeholder='Enter Account Number'
                                  />

                                  <p className='errors'>
                                    {errors.accountNumber?.message}
                                  </p>
                                </div>
                              </div>

                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='accountType'
                                    className='form-label'>
                                    Swift Code*
                                  </label>
                                  <input
                                    type='text'
                                    defaultValue={thedata.swiftCode}
                                    className={
                                      errors.swiftCode
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id='accountType'
                                    {...register("swiftCode")}
                                    required
                                    placeholder='Enter Swift Code'
                                  />

                                  <p className='errors'>
                                    {errors.swiftCode?.message}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* end of form row */}

                            <div className='row my-4'>
                              <div className='col'></div>
                              <div className='col-auto'>
                                {loading ? (
                                  <button className='btn btn-primary'>
                                    <Loader
                                      type='TailSpin'
                                      color='#ffffff'
                                      height={20}
                                      width={20}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className='btn btn-primary'
                                    type='submit'>
                                    Submit
                                  </button>
                                )}
                                {/* <button className="btn btn-primary" type="submit">Submit</button> */}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default  EditCompany;
